import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import 'ant-design-vue/dist/antd.css';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);
// 创建 axios 实例
axios.defaults.timeout = 50000;   // 超时时间
// axios.defaults.baseURL = "http://localhost:9999/";  // 默认地址
// axios.defaults.baseURL = "http://192.168.0.191:9999";  // 默认地址
axios.defaults.baseURL = "http://127.0.0.1:9999";  // 默认地址
Vue.config.productionTip = false;
Vue.use(dataV);
Vue.use(Antd);
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';


// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './static/scss/style.scss';


//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

